import React from 'react'
import { BsFillArrowUpCircleFill } from 'react-icons/bs'
import logo from '../../assets/nav-logo-wh.png'
import './FooterStyles.css'
import {Link} from 'react-router-dom'
import { Link as LinkRoll } from 'react-scroll'
import DHCS from "../../assets/DHCS-logo2.png";
import JointCommission from "../../assets/joint_commission_logo.webp";

const Footer = () => {
    return (

<>
        <div className='footer'>
            <div className="container">
                <div className="top">
                    <div className="logo-footer">
                    <img src={logo} alt="Healing Path Detox Logo" loading="lazy"/>
                    </div>
                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500} >
                        <BsFillArrowUpCircleFill className='footer-icon' />
                    </LinkRoll>
                </div>
                <div className="col-container">

                    <div className="col">
                        <h3>Navigation</h3>
                        
                        <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500} >
                        <Link to='/'><p>Home</p></Link>
                        </LinkRoll>

                        <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500} >
                        <Link to='/insurance'><p>Insurance</p></Link>
                        </LinkRoll>

                        <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500} >
                        <Link to='/contact'><p>Contact</p></Link>
                        </LinkRoll>
                    </div>


                    <div className="col">
                        <h3>SERVICES</h3>
                        <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500} >
                        <Link to='/substance-abuse'><p>Addiction Treatment</p></Link>
                        </LinkRoll>

                        <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500} >
                        <Link to='/detox'><p>Detox Programs</p></Link>
                        </LinkRoll>

                        <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500} >
                        <Link to='/jobs'><p>Employment Assistance</p></Link>
                        </LinkRoll>
                    </div>

                    <div className="col address">
                        <h3>Healing Path Detox LLC</h3>

                        <p>7661 Amberleaf Circle 1,2,3,4<br/>Huntington Beach, Ca 92648</p>
                        <hr />

                        <p>77235 Santa Monica Blvd Suite B<br/>West Hollywood, Ca 90046 </p>
                        <hr />
                        <p>Phone: <a href="tel:3103438684"> +1 (310) 343 8684</a></p>
                        <p><p>Info@HealingPathDetox.org</p></p>

                        <div>

</div>
                    </div>


                    <div className="certification">

                        <div className="certificationLeft">

                            <h3>Certified/Licensed by the State Department of Health Care Services</h3>
                            <p>Huntington Beach:</p>
                            <p>License Number: 300368BP <br />Expiration date: 07/31/26</p>
                            <br />

                            <p>Santa Monica:</p>
                            <p>Certification Number: 191173AP <br />Expiration date: 11/30/26</p>
                            <br />

                            <a href="https://data.chhs.ca.gov/dataset/sud-recovery-treatment-facilities"
                            target="_blank"
                            rel="noreferrer">( View License & Certification Information here ).</a>
                                <div className='certificationLeftImage'>
                                    <img
                                    src={DHCS}
                                    alt="DHCS Logo"
                                    loading="lazy"
                                    />
                                </div>
                        </div>

                        <div className="certificationRight">
                            <h3>Healing Path Detox is accredited by The Joint Commission and have been awarded its Gold Seal of Approval™</h3>
                            
                                <div className='certificationRightImage'>
                                    <img
                                    src={JointCommission}
                                    alt="Joint Commission Logo"
                                    loading="lazy"
                                    />
                                </div>
                        </div>

</div>


                </div>
                <p className='copyright'><p>&copy; 2025 Healing Path Detox. All rights reserved.</p></p>


            </div>

        </div>
</>
    )
}

export default Footer
